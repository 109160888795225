<template>
  <div id='payment-state-success'>
    <div class="icon-wrapper"><icon-check :color="'#fff'"/></div>

    <h1 class="title">{{ $t(titleKey) }}</h1>
    <div class="transaction-data">
      <span class="label">{{ $t('pages.payment.finalization.states.success.labels.transaction') }}</span>
      <span class="value">{{ transactionId }}</span>
    </div>
    <div class="information">
      <span class="label">{{ $t('pages.payment.finalization.states.success.labels.email') }}</span>
      <span class="value">{{ emailAddress }}</span>
    </div>
    <div class="buttons">
      <base-button
        :isSecondary="true"
        :text="$t('pages.payment.finalization.states.success.buttons.voucher')"
        :link="voucherLink"
      />
      <base-button
        :isSecondary="true"
        :text="$t('pages.payment.finalization.states.success.buttons.invoice')"
        :link="invoiceLink"
      />
    </div>
  </div>
</template>

<script>
import IconCheck from '@/components/shared/elements/icons/IconCheck';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'PaymentStateSuccess',
  props: {
    titleKey: {
      type: String,
      required: false,
      default: 'pages.payment.finalization.states.success.title',
    },
    transactionId: {
      type: String,
      required: true,
    },
    emailAddress: {
      type: String,
      required: true,
    },
    voucherLink: {
      type: String,
      required: true,
    },
    invoiceLink: {
      type: String,
      required: true,
    },
  },
  components: {
    IconCheck,
    BaseButton,
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  mounted() {
    // eslint-disable-next-line no-undef
    gtag('event', 'conversion', {
      send_to: 'AW-1034735917/x8d1CMTRvMsDEK2is-0D',
      value: 1.0,
      currency: 'HUF',
      transaction_id: '',
    });
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
$iconWrapperSize: 135px;
$buttonsWidth: 280px;

#payment-state-success {
  text-align: center;
}

.icon-wrapper {
  width: $iconWrapperSize;
  height: $iconWrapperSize;
  background-color: $globalInfoColor;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 25px;

  @media screen and (max-width: $breakpointDownSm) {
    width: calc(#{$iconWrapperSize} - 15px);
    height: calc(#{$iconWrapperSize} - 15px);
  }

  @media screen and (max-width: $breakpointDownXs) {
    width: calc(#{$iconWrapperSize} - 35px);
    height: calc(#{$iconWrapperSize} - 35px);
  }

  .icon-check {
    margin-top: -20px;
    margin-right: -30px;

    &::v-deep {
      svg {
        width: 122px;
        height: 95px;

         @media screen and (max-width: $breakpointDownSm) {
          width: 122px;
          height: 80px;
        }
      }
    }
  }
}

.title {
  font-size: 25px;
  font-weight: 700;
  color: $globalInfoColor;
  margin: 0 0 20px;
  text-align: center;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 20px;
  }
}

.label,
.value {
  display: block;
  line-height: 1.2;
}

.label {
  font-weight: 500;
  font-size: 15px;
  margin: 0 0 2px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 12px;
    line-height: 1.4;
  }
}

.value {
  font-weight: 700;
  font-size: 20px;
  color: $primaryBlue;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 16px;
  }
}

.transaction-data {
  margin: 0 0 45px;

  @media screen and (max-width: $breakpointDownSm) {
    margin: 0 0 15px;
  }
}

.buttons {
  margin: 20px 0 0;
  display: grid;

  @media screen and (max-width: $breakpointDownSm) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: $breakpointDownXs) {
    display: block;
  }

  .button-base {
    min-width: $buttonsWidth;

    @media screen and (max-width: $breakpointDownSm) {
      min-width: 100%;
      padding: 0 25px;
      margin: 0 auto;
    }

    @media screen and (max-width: $breakpointDownXXs) {
      padding: 0;
    }

    &:first-child {
      margin-bottom: 20px;

      @media screen and (max-width: $breakpointDownSm) {
        margin: 0 auto 15px;
      }

      @media screen and (max-width: $breakpointDownXs) {
        margin: 0 0 15px;
      }
    }
  }
}

</style>
